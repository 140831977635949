import { collection, doc, getDoc, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebase/firebase";
export let colors = {
  primary: "",
  secondary: "",
  options: "",
  fill: "",
  optionBackground: "",
  buttons: "",
};
export let poll_id = "20";
let url = document.location.href;

export async function getDocument() {
  const docRef = doc(db, "polls", poll_id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log("No such document!");
  }
}

export function setPoll_ID(id) {
  poll_id = id;
}
export async function getCurrentUserAnswers(userEmail) {
  const docRef = doc(db, "polls", poll_id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    let temp = [];
    temp.find((x) => x.user_id === docSnap.data().user_id);
    return docSnap
      .data()
      .users?.find((x) => x.email?.toLowerCase() == userEmail?.toLowerCase())
      ?.answers;
  } else {
    return false;
  }
}
export async function getColors() {
  const q = query(collection(db, "polls"));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const docs = [];
    querySnapshot.forEach((doc) => {
      if (doc.id == poll_id) docs.push(doc.data());
    });
    if (
      colors.primary == "none" ||
      colors.fill == "none" ||
      colors.secondary == "none" ||
      colors.options == "none" ||
      colors.optionBackground == "none"
    ) {
      colors = {
        primary: "f1f1f1",
        secondary: "f1f1f1",
        options: "f1f1f1",
        fill: "f1f1f1",
        optionBackground: "f1f1f1",
      };
    } else {
      colors = docs[0].colors;
    }
  });
  return () => {
    unsubscribe();
  };
}
