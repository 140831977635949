// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAn2V7UM3gnarfDwYwNIj6DPo4dIXL08NA",
    authDomain: "cloudvie-polls.firebaseapp.com",
    projectId: "cloudvie-polls",
    storageBucket: "cloudvie-polls.appspot.com",
    messagingSenderId: "859228487400",
    appId: "1:859228487400:web:972d53b0e02b4ed949535d",
    measurementId: "G-ZGHZ229EFE"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
export const db = getFirestore(firebaseApp);
