import { useEffect } from 'react';
import { percentages, primary, secondary } from '../../colors/colors';
import { colors } from '../../helpers/helpers';
import classes from './bar.module.css'
const Bar = (props) => {
    let value = props.value;
    let max = props.max;
    let widthStyle = '0%';
    let width = 0
    if (max > 0) {
        width = Math.round((value / max) * 100);
    }

    return (
        <div className={classes.outerContainer}>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '18px', marginBottom: '-5px', width: '100%' }}>
                <div style={{ borderWidth: '0px', borderStyle: 'solid', width: '100%', borderRadius: '100px', padding: '5px', paddingRight: '10px', paddingLeft: '10px', display: 'flex', justifyContent: 'flex-start',color:colors.primary }}>{props.option}</div>
                <div style={{ alignSelf: 'flex-end', padding: '8px', color: colors.primary, borderRadius: '300px', fontSize: '15px', width: '50px' }}>{width}%</div>
            </div>
            <div className={classes.container}
                style={{ backgroundColor: colors.primary }}
            >
                <div style={{ borderRadius: '300px', width: width.toString() + '%', height: '20px', display: 'flex', backgroundColor: colors.secondary, alignSelf: 'flex-start' }} className={classes.fill}>
                </div>
            </div>
        </div>
    )
}
export default Bar;