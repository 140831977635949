import "./App.css";
import { useEffect, useState } from "react";
import QuestionContainer from "./components/questionContainer";
import Bar from "./components/bar/bar";
import {
  colors,
  getColors,
  getCurrentUserAnswers,
  getDocument,
  poll_id,
  setPoll_ID,
} from "./helpers/helpers";
import { collection, doc, onSnapshot, query, setDoc } from "firebase/firestore";
import { db } from "./firebase/firebase";
import { async } from "@firebase/util";
let firstTime = false;
let firstRender = 0;
function App() {
  const [currentQuestion, setCurrentQuestion] = useState();
  const [chosenOptionState, setChosenOptionState] = useState();
  const [curentHistoryIndex, setCurrentHistoryIndex] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [checkedAnsweredQuestions, setCheckedAnsweredQuestions] =
    useState(false);
  const [clickedOnHistory, setClickedOnHistory] = useState(false);
  const [answered, setAnswered] = useState(true);
  const [user, setUser] = useState({});
  const [pollHistory, setPollHistory] = useState();
  const [questions, setQuestions] = useState();
  const [answers, setanswers] = useState([]);
  useEffect(() => {
    if (!firstTime) {
      // to listen for colors updates from firebase
      getColors();
      firstTime = true;
    }
  }, []);

  useEffect(() => {
    var url = new URL(document.location.href);
    var id = url?.searchParams.get("id");
    var username = url?.searchParams.get("username");
    var email = url?.searchParams.get("email");
    setPoll_ID(id);

    setUser({ username: username, email: email });
    let storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser == undefined || storedUser == null) {
      localStorage.setItem("user", JSON.stringify(user)); //store colors
    }
  }, []);
  function calculateTotalresult(options) {
    let total = 0;
    for (let i = 0; i < options.length; i++) {
      total += options[i].value;
    }
    return total;
  }
  useEffect(() => {
    if (questions) {
      let temp = [];
      for (let i = 0; i < questions.length; i++) {
        if (questions[i].status === "2") {
          temp.push(questions[i]);
        }
      }
      setCurrentHistoryIndex(0);
      setPollHistory(temp);
    }
  }, [currentQuestion?.question, questions?.length]); // removed questions from dependencies

  useEffect(() => {
    async function getAnswers() {
      if (user.email) {
        // check for answers on firebase if there's an email in the url
        await getCurrentUserAnswers(user?.email).then((answers) => {
          if (answers?.find((x) => x.question === currentQuestion?.question)) {
            setAnswered(true);
            console.log("answered");
          } else {
            setAnswered(false);
            console.log(" not answered");
          }
        });
      } else if (!user.email && currentQuestion?.question) {
        // if there's a problem , remove currentQuestion?.question
        // if there's no email in the url, check for answerd questions in localStorage
        let answers = JSON.parse(
          localStorage.getItem(`${poll_id}answeredQuestions`)
        );
        // console.log(answers);

        if (answers?.find((q) => q == currentQuestion?.question)) {
          console.log("local answereddddddddd");
          // setAnswered(true);
        } else {
          console.log("local not answered");
          // setAnswered(false)
        }
      }
    }
    getAnswers().then(() => {
      setCheckedAnsweredQuestions(true);
    });
  }, [currentQuestion?.question]);
  useEffect(() => {
    try {
      // console.log(db);
      const q = query(collection(db, "polls"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          if (doc.id == poll_id) docs.push(doc.data());
          // console.log(doc.data());
        });
        console.log(docs[0].questions);
        // console.log(docs[0].questions);
        setQuestions(docs[0].questions);
        if (docs[0].current !== null) {
          setCurrentQuestion(docs[0].current);
          if (docs[0].current.active) {
            setCurrentHistoryIndex(0);
            setShowResult(false);
          } else {
            setCurrentHistoryIndex(0);
            setShowResult(true);
          }
        } else if (docs[0].current === null) {
          setCurrentQuestion(null);
          setShowResult(false);
        }
      });
      return () => {
        unsubscribe();
      };
    } catch (err) {
      // console.log(err);
    }
  }, []);

  async function addUserAction(question, chosenOption, doc) {
    try {
      let document = doc; // get instance of the locally edited document
      if (
        document.users.length < 1 ||
        document.users == undefined ||
        !document.users ||
        document.users == null
      ) {
        // if no users yet
        document.users.push({
          // add user
          username: user.username ? user.username : "",
          email: user.email ? user.email : "",
          answers: [
            {
              question: question,
              option: chosenOption,
              time: new Date().getTime(),
            },
          ],
        });
      } else {
        // if there are users
        let index = 0;
        console.log(user);
        let exists = false;
        for (let j = 0; j < document.users.length; j++) {
          // find user
          if (
            document.users[j].email.toLowerCase() == user.email.toLowerCase() ||
            document.users[j].username.toLowerCase() ==
              user.username.toLowerCase()
          ) {
            index = document.users.indexOf(document.users[j]);
            exists = true;
            index = j;
          }
        }
        if (exists) {
          // if user exists then append the answer to the user
          document.users[index].answers.push({
            // append answer
            question: question,
            option: chosenOption,
            time: new Date().getTime(),
          });
          exists = false;
        } else {
          // if user does not exist then append the user and the answer to users
          document.users.push({
            // add user
            email: user.email ? user.email : "",
            username: user.username ? user.username : "",
            answers: [
              {
                question: question,
                option: chosenOption,
                time: new Date().getTime(),
              },
            ],
          });
        }
      }
      return document;
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    //  document.users
  }, []);

  async function saveResult(question, chosenOption) {
    setChosenOptionState(chosenOption);
    setAnswered(true);
    let document = await getDocument();
    let index = -1;
    for (let i = 0; i < document.questions.length; i++) {
      if (document.questions[i].question === question) {
        index = i;
      }
    }
    let optionIndex = -1;
    let tempOption = document.questions[index].options.find((option, index) => {
      if (option.text == chosenOption) {
        optionIndex = index;
        return option;
      }
    });
    console.log(tempOption);
    var storedAnswers = JSON.parse(
      localStorage.getItem(`${poll_id}answeredQuestions`)
    ); //get them back
    if (storedAnswers == null || storedAnswers == undefined) {
      localStorage.setItem(
        `${poll_id}answeredQuestions`,
        JSON.stringify([question])
      ); //store question
    } else {
      localStorage.setItem(
        `${poll_id}answeredQuestions`,
        JSON.stringify([...storedAnswers, question])
      ); //store question
    }
    tempOption.value++; // add 1 score to chosen option
    document.questions[index].options[optionIndex] = tempOption; // preparing the document
    document.current = document.questions[index];

    let finalDocument = await addUserAction(question, chosenOption, document);
    console.log(finalDocument);

    await getCurrentUserAnswers(user?.email).then(async (answers) => {
      // console.log(answers);
      console.log("answers====>", answers);
      if (answers?.find((x) => x.question === currentQuestion?.question)) {
        console.log("answereeeeeeedddd");
      } else {
        await setDoc(doc(db, "polls", poll_id), finalDocument);
      }
    });
  }
  const getAnswer = async () => {
    var url = new URL(document.location.href);
    var email = url?.searchParams.get("email");
    var temp = {};
    await getCurrentUserAnswers(email).then(async (answers) => {
      console.log("user", email);
      console.log("Now the answers", answers);
      for (let item of answers) {
        const quest = item?.question;
        const opt = item?.option;
        console.log("opt", opt);
        temp = { ...temp, [quest]: opt };
      }
      setanswers(temp);
    });
  };

  console.log("stateAnswers", answers);
  useEffect(() => {
    if (pollHistory?.length <= 1) {
      setCurrentHistoryIndex(0);
    }
    if (pollHistory === undefined) {
    }
  }, [pollHistory]);
  function nextHistory() {
    if (curentHistoryIndex < pollHistory.length - 1) {
      setCurrentHistoryIndex(curentHistoryIndex + 1);
    }
  }
  function prevHistory() {
    if (curentHistoryIndex > 0) {
      setCurrentHistoryIndex(curentHistoryIndex - 1);
    }
  }
  useEffect(() => {
    setTimeout(() => {
      getAnswer();
    }, 100);
  }, []);
  console.log("answers[currentQuestion]", answers[currentQuestion?.question]);
  return (
    <div className="App">
      <div className="container">
        <div
          style={{
            width: "90%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!currentQuestion && !showResult && !pollHistory && (
            <div>There are no polls yet...</div>
          )}
          {currentQuestion && !showResult && (
            <>
              <div
                style={{
                  color: colors.primary,
                  fontWeight: "bold",
                  margin: "5px",
                }}
              >
                Choose one answer
              </div>
              <QuestionContainer>{currentQuestion.question}</QuestionContainer>
              <div className="options">
                {checkedAnsweredQuestions &&
                  currentQuestion?.options.map((option, key) => {
                    return (
                      <div
                        key={key}
                        style={{
                          borderRadius: "4vw",
                          borderWidth:
                            chosenOptionState === option.text ||
                            answers[currentQuestion?.question] === option?.text
                              ? "3px"
                              : "1px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div />
                        {
                          <div
                            style={{
                              borderRadius: "4vw",
                              padding: "10px",
                              borderStyle: "solid",
                              borderWidth:
                                chosenOptionState === option.text ||
                                answers[currentQuestion?.question] ===
                                  option?.text
                                  ? "3px"
                                  : "1px",
                              borderColor:
                                chosenOptionState == option.text ||
                                answers[currentQuestion?.question] ===
                                  option?.text
                                  ? "#f36852"
                                  : "black",
                              backgroundColor: answered
                                ? "#f0f0f0f0"
                                : colors.primary,
                              color: answered
                                ? chosenOptionState === option.text ||
                                  answers[currentQuestion?.question] ===
                                    option?.text
                                  ? "#f36852"
                                  : "#000000"
                                : "white",
                            }}
                            onClick={() =>
                              !answered &&
                              saveResult(currentQuestion.question, option.text)
                            }
                            className={answered ? "disabled" : "option"}
                          >
                            {option?.text}
                          </div>
                        }
                      </div>
                    );
                  })}
              </div>
            </>
          )}
          {showResult && currentQuestion && (
            <>
              <div
                style={{
                  color: "black",
                  fontFamily: "sans-serif",
                  marginTop: "5px",
                  marginBottom: "10px",
                  width: "100%",
                  margin: "10px",
                  color: colors.primary,
                  fontWeight: "bold",
                }}
              >
                Results
              </div>
              <QuestionContainer>{currentQuestion.question}</QuestionContainer>
              <div className="options">
                {currentQuestion.options.map((option, key) => (
                  <div
                    key={key}
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Bar
                      option={option.text}
                      value={option.value}
                      max={calculateTotalresult(currentQuestion.options)}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {!showResult &&
            !currentQuestion &&
            pollHistory &&
            pollHistory.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  paddingLeft: "10px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "15px",
                  }}
                >
                  <div
                    onClick={() => {
                      if (clickedOnHistory) {
                        setCurrentHistoryIndex(0);
                        setClickedOnHistory(false);
                      } else {
                        setCurrentHistoryIndex(0);
                        setClickedOnHistory(true);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      color: colors.primary,
                      width: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "1px",
                      borderRadius: "300px",
                      borderStyle: "solid",
                      borderWidth: "0.5px",
                    }}
                  >
                    {clickedOnHistory ? "Hide" : "Show"} History
                  </div>
                </div>
              </div>
            )}
          {!showResult &&
            !currentQuestion &&
            pollHistory &&
            clickedOnHistory &&
            pollHistory.length > 0 && (
              <div className="bounce">
                <QuestionContainer>
                  {pollHistory[curentHistoryIndex]?.question}
                </QuestionContainer>
                <div className="options">
                  {pollHistory[curentHistoryIndex]?.options.map(
                    (option, key) => (
                      <div
                        key={key}
                        style={{
                          width: "90%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Bar
                          option={option.text}
                          value={option.value}
                          max={calculateTotalresult(
                            pollHistory[curentHistoryIndex].options
                          )}
                        />
                      </div>
                    )
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  {
                    <div
                      onClick={() => {
                        if (curentHistoryIndex <= 0) {
                          return;
                        }
                        prevHistory();
                      }}
                      className="nextContainer"
                    >
                      <div
                        style={{ backgroundColor: colors.primary }}
                        className="next"
                      >
                        {"<"}
                      </div>
                    </div>
                  }
                  {
                    <div
                      onClick={() => {
                        if (curentHistoryIndex > pollHistory.length - 1) {
                          return;
                        }
                        nextHistory();
                      }}
                      className="nextContainer"
                    >
                      <div
                        style={{ backgroundColor: colors.primary }}
                        className="next"
                      >
                        {">"}
                      </div>
                    </div>
                  }
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default App;
