import { primary } from "../colors/colors";
import { colors } from "../helpers/helpers";

const QuestionContainer = (props) => {
    return (
        <div style={{ color: colors.primary, alignSelf: 'center', marginRight: '10px', marginLeft: '10px', marginBottom: '10px', display: 'flex', justifyContent: 'flex-start', width: '90%', borderRadius: '300px', borderWidth: '0px', fontWeight: 'bold', borderColor: 'black', borderStyle: 'solid', padding: '5px', marginLeft: '5%' }}>
            {props.children}
        </div>
    )

}
export default QuestionContainer;